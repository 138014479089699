import { Listeners } from "../enumerations/web";
import { AddNotificationBanner } from "./addNotificationBanner";
import { CartController } from "./cart";

declare const CART_CONTROLLER: CartController;
declare const ADD_NOTIFICATION_BANNER: AddNotificationBanner;

export class AddAllBoardDialog {
	private backgroundBlockerElem?: HTMLDivElement;
	private containerElem?: HTMLDivElement;
	private cancelButton?: HTMLDivElement;
	private addAllButton?: HTMLDivElement;

	private addSkus: string[] = [];

	public init() {
		this.backgroundBlockerElem = document.querySelector("#add-all-board-background-blocker") as HTMLDivElement;
		this.containerElem = document.querySelector("#add-all-board-dialog-container") as HTMLDivElement;
		this.cancelButton = document.querySelector("#add-all-board-button-cancel") as HTMLDivElement;
		this.addAllButton = document.querySelector("#add-all-board-button-add") as HTMLDivElement;

		this.hookEvents();
	}

	public onShow(addSkus: string[]) {
		this.addSkus = addSkus;
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.remove("owHidden");
		}

		if (this.containerElem) {
			this.containerElem.classList.remove("owHidden");
		}
	}

	public onHide() {
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.add("owHidden");
		}

		if (this.containerElem) {
			this.containerElem.classList.add("owHidden");
		}
	}

	private async onAddAll() {
		const addAmount = this.addSkus.length;
		this.onHide();
		await CART_CONTROLLER.addBoardItemsToCart(this.addSkus);
		ADD_NOTIFICATION_BANNER.onShow(addAmount, "addAll");
	}

	private hookEvents() {
		if (this.cancelButton) {
			this.cancelButton.addEventListener(Listeners.CLICK_EVENT, this.onHide.bind(this));
		}

		if (this.addAllButton) {
			this.addAllButton.addEventListener(Listeners.CLICK_EVENT, this.onAddAll.bind(this));
		}
	}
}
