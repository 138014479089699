import { DataElements, Listeners } from "../enumerations/web";
import { postAjaxJSON } from "../utils/ajax";
import { trackEvent } from "../utils/tracking";
import { HamburgerMenu } from "./hamburgerMenu";

import { CreateBoardDialog } from "./createBoardDialog";

export const HEADER_OFFSET = 61;

declare const HAMBURGERMENU: HamburgerMenu;
declare const Quiq: any;
declare const CREATE_BOARD_DIALOG: CreateBoardDialog;

(window as any).HAMBURGERMENU = new HamburgerMenu();
(window as any).CREATE_BOARD_DIALOG = new CreateBoardDialog();

class Header {
	private isInitialized: boolean = false;
	private chat?: any;
	private chatContactPoint?: string = "";

	public async init() {
		if (this.isInitialized) {
			return;
		}
		this.isInitialized = true;

		// new menu for native app
		const isNativeElem = document.querySelector(DataElements.IS_NATIVE_SELECTOR) as HTMLInputElement;
		if (isNativeElem && isNativeElem.value) {
			const navigation = document.querySelector(".navigation");

			if (navigation) {
				navigation.classList.add("navigationNative");
			}
			const createBtn = document.querySelector("#create-new-board-navbtn");
			const boardsBtn = document.querySelector("#my-boards-navbtn");
			const settingsBtn = document.querySelector("#settings-navbtn");
			const settingsElement = document.querySelector(".nativeMenuSettings");
			if (createBtn && boardsBtn && settingsBtn) {
				// CREATE NEW BOARD
				createBtn.addEventListener("click", (e) => {
					createBtn.classList.add("nativeMenuButtonActive");
					settingsBtn.classList.remove("nativeMenuButtonActive");
					boardsBtn.classList.remove("nativeMenuButtonActive");
					CREATE_BOARD_DIALOG.onShow(e as MouseEvent);
				});
				// MY BOARDS
				boardsBtn.addEventListener("click", () => {
					if (window.location.href.includes("/portfolio")) {
						settingsBtn.classList.remove("nativeMenuButtonActive");
						boardsBtn.classList.add("nativeMenuButtonActive");
						if (settingsElement) {
							settingsElement.classList.add("nativeMenuSettingsClosed");
						}
					} else {
						window.location.href = "/portfolio";
					}
				});
				// SETTINGS
				settingsBtn.addEventListener("click", () => {
					if (settingsElement) {
						settingsElement.classList.remove("nativeMenuSettingsClosed");
						settingsBtn.classList.add("nativeMenuButtonActive");
						boardsBtn.classList.remove("nativeMenuButtonActive");
					}
				});
				// SETTINGS BACK/CLOSE BUTTONS
				if (settingsElement) {
					const closeBtns = document.querySelectorAll(`.nativeMenuSettingsClose,
					 .nativeMenuSettingsBackLink`);
					if (closeBtns.length) {
						closeBtns.forEach((btn) => {
							btn.addEventListener("click", (e) => {
								e.stopPropagation();
								this.toggleSettings(settingsBtn, boardsBtn, settingsElement);
							});
						});
					}
				}
				// CREATE CLOSE BUTTONS
				const createBoardCloseBtns = document.querySelectorAll(`#create-board-background-blocker,
				 #create-board-room-type-close-button`);
				if (createBoardCloseBtns.length) {
					createBoardCloseBtns.forEach((btn) => {
						btn.addEventListener("click", () => {
							createBtn.classList.remove("nativeMenuButtonActive");
							if (window.location.href.includes("/portfolio")) {
								boardsBtn.classList.add("nativeMenuButtonActive");
							}
						});
					});
				}
			}
		}
		if (!isNativeElem || !isNativeElem.value) {
			// initialize hamburger menu if !native
			HAMBURGERMENU.init();
			let boardId = "";
			// Initialize chat
			const boardIdElem = document.querySelector(DataElements.BOARD_ID_SELECTOR) as HTMLInputElement;
			if (boardIdElem) {
				boardId = boardIdElem.value;
			}

			try {
				const chatContactPoint: string = await postAjaxJSON("/data/chat-contact", {
					boardId
				});

				await this.setupChat(chatContactPoint);
			} catch (err) {
				// tslint:disable-next-line:no-console
				console.log(err.stack);
			}
		}
	}

	public async setupChat(chatContactPoint: string): Promise<void> {
		return new Promise(async (resolve, reject) => {
			if (chatContactPoint) {
				this.chatContactPoint = chatContactPoint;
				// https://developers.goquiq.com/docs/webchat/#/reference/types?id=options
				const chatOptions = {
					contactPoint: chatContactPoint,
					colors: {
						primary: "#373D4A"
					},
					position: {
						bottom: 10
					},
					forceWebChat: true,
					displayMode: "docked",
					fontFamily: "GT-Eesti-Text-Book, Helvetica, Arial, sans-serif",
					enforceAgentAvailability: false,
					showDefaultLaunchButton: false,
					customLaunchButtons: ["#design-chat-button"]
					// headerText: "We're here to help if you have any questions!"
				};

				try {
					this.chat = Quiq(chatOptions);
					if (this.chat) {
						this.chat.on("sdkInitialized", (e) => {
							const chatButton = document.body.querySelector("#design-chat-button") as HTMLDivElement;
							if (chatButton) {
								// tslint:disable-next-line:no-console
								console.log("Chat Initialized");

								chatButton.classList.remove("owHidden");
								chatButton.addEventListener(Listeners.CLICK_EVENT, this.onChatButtonClicked.bind(this));
							}
						});

						const { supported } = await this.chat.getIsSupportedBrowser();
						if (!supported) {
							// tslint:disable-next-line:no-console
							console.error("Chat does not support your browser!");
						}
					}
				} catch (err) {
					// tslint:disable-next-line:no-console
					console.log(err.stack);
				}
			}
		});
	}

	public toggleSettings(settingsBtn, boardsBtn, settingsElement) {
		settingsBtn.classList.remove("nativeMenuButtonActive");
		settingsElement.classList.add("nativeMenuSettingsClosed");
		if (window.location.href.includes("/portfolio")) {
			boardsBtn.classList.add("nativeMenuButtonActive");
		}
	}

	public onChatButtonClicked(): void {
		trackEvent("Button", "View Chat", this.chatContactPoint);
	}
}

(window as any).HEADER = new Header();

declare const HEADER: Header;

window.addEventListener(Listeners.LOAD_EVENT, async () => {
	await HEADER.init();
});
