import { Listeners } from "../enumerations/web";
import { CartController } from "./cart";

const NOTIFICATION_ANIMATION_TIMEOUT = 500;
const NOTIFICATION_ACTIVE_TIME = 1800;

declare const CART_CONTROLLER: CartController;

export class AddNotificationBanner {
	private containerElem?: HTMLDivElement;
	private closeButton?: HTMLDivElement;
	private viewCartButton?: HTMLDivElement;
	private quantityElement?: HTMLDivElement;

	public init() {
		this.containerElem = document.querySelector("#add-notification-container") as HTMLDivElement;
		this.closeButton = document.querySelector("#add-notification-close") as HTMLDivElement;
		this.viewCartButton = document.querySelector("#add-notification-open-card") as HTMLDivElement;
		this.quantityElement = document.querySelector("#add-notification-quantity") as HTMLDivElement;

		this.hookEvents();
	}

	public onShow(quantity: number, type: string) {
		if (this.quantityElement) {
			this.quantityElement.textContent = this.getBannerText(quantity, type);
			if (this.viewCartButton) {
				this.viewCartButton.style.display = type === "addAll" ? "block" : "none";
			}
		}

		if (this.containerElem) {
			this.containerElem.classList.remove("owHidden");
			setTimeout(() => {
				if (this.containerElem) {
					this.containerElem.classList.add("active");
				}
			}, 50);
		}

		setTimeout(() => {
			this.onHide();
		}, NOTIFICATION_ACTIVE_TIME + NOTIFICATION_ANIMATION_TIMEOUT);
	}

	public onHide() {
		if (this.containerElem) {
			this.containerElem.classList.remove("active");
			setTimeout(() => {
				if (this.containerElem) {
					this.containerElem.classList.add("owHidden");
				}
			}, NOTIFICATION_ANIMATION_TIMEOUT);
		}
	}

	private openCart() {
		this.onHide();
		CART_CONTROLLER.open();
	}

	private getBannerText(quantity: number, type: string) {
		let bannerText;
		switch (type) {
			case "addAll":
				bannerText = quantity === 1 ? `${quantity} item added to cart` : `${quantity} items added to cart`;
				break;
			case "addToCart":
				bannerText = "Item added to cart";
				break;
			case "addToBoard":
				bannerText = "Item added to board";
				break;
		}
		return bannerText;
	}

	private hookEvents() {
		if (this.closeButton) {
			this.closeButton.addEventListener(Listeners.CLICK_EVENT, this.onHide.bind(this));
		}

		if (this.viewCartButton) {
			this.viewCartButton.addEventListener(Listeners.CLICK_EVENT, this.openCart.bind(this));
		}
	}
}
