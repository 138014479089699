import { ButtonClasses, DataElements, Listeners } from "../enumerations/web";
import { IBrand } from "../interfaces/brandInterfaces";
import {
	IBrandWithProducts,
	ICartBrands,
	ICartItem,
	ICartProduct,
	SortedBrandWithProducts
} from "../interfaces/productInterfaces";
import { clearGuestCartData, getCartData, getGuestCartData, updateCartData } from "../utils/cart";
import { copyObject } from "../utils/obj";
import { getBrands, getProductsData } from "../utils/products";
import { trackEvent } from "../utils/tracking";
import { SlidingDrawer } from "./editor/slidingDrawer";

declare const SLIDING_DRAWER: SlidingDrawer;

const CART_ACTION_SELECTORS = [
	"#action-toolbar-add-all-button",
	".counter-cell.decrement",
	".counter-cell.increment",
	".product-remove-button"
];

export class CartController {
	private onOpenHandlers?: () => void;
	private onCloseHandlers?: () => void;
	private customerId: number = 0;
	private cartElement?: HTMLDivElement;
	private brandsContainer?: HTMLDivElement;
	private noProductsBlock?: HTMLDivElement;
	private skeletonLoader?: HTMLDivElement;
	private openButton?: HTMLDivElement;
	private closeButton?: HTMLDivElement;
	private brandWithProducts: ICartBrands = {};
	private sortedBrandsWithProducts: SortedBrandWithProducts[] = [];
	private brands: IBrand[] = [];
	private imagePath: string = "";
	private wasChanges: boolean = true;
	private tabs: any = {};
	private cartItems: ICartItem[] = [];
	private isGuest: boolean = false;

	public async init(): Promise<void> {
		this.customerId = +(document.querySelector(DataElements.CUSTOMER_ID_SELECTOR) as HTMLInputElement).value;
		this.cartElement = document.querySelector("#cart") as HTMLDivElement;
		this.brandsContainer = document.querySelector("#cart-brands-container") as HTMLDivElement;
		this.closeButton = document.querySelector("#cart-close-button") as HTMLDivElement;
		this.openButton = document.querySelector("#cart-header-button") as HTMLDivElement;
		this.noProductsBlock = document.querySelector("#cart-no-products") as HTMLDivElement;
		this.skeletonLoader = document.querySelector("#cart-skeleton-loader") as HTMLDivElement;
		this.imagePath = (document.querySelector(DataElements.ICON_PATH_SELECTOR) as HTMLInputElement).value;
		this.isGuest = Boolean((document.querySelector(DataElements.IS_GUEST_SELECTOR) as HTMLInputElement).value);

		this.toggleCartLoadingIcon(true);

		if (this.isGuest) {
			this.cartItems = await getGuestCartData();
		} else {
			// If not logged in as guest, but have a guest cart, import it.
			// TODO: Prompt user
			this.cartItems = await getCartData(this.customerId);
			const guestCartItems = await getGuestCartData();
			if (guestCartItems.length) {
				await this.addBoardItemsToCart(guestCartItems);
				await clearGuestCartData();
			}
		}

		this.toggleCartLoadingIcon(false);

		this.checkCartIconView(this.calculateProductsCount());
		this.brands = await getBrands();
		if (this.cartElement) {
			this.cartElement.classList.remove("owHidden");
		}

		this.hookEvents();
	}

	public setHandlers(onOpenHandler?: () => void, onCloseHandler?: () => void) {
		if (onOpenHandler) {
			this.onOpenHandlers = onOpenHandler;
		}

		if (onCloseHandler) {
			this.onCloseHandlers = onCloseHandler;
		}
	}

	public open(): void {
		if (this.cartElement) {
			if (!this.cartElement.classList.contains("visible")) {
				this.cartElement.classList.add("visible");
				trackEvent("Button", "View Cart");

				if (this.onOpenHandlers) {
					this.onOpenHandlers();
				}

				if (this.wasChanges || !this.getCartItems().length) {
					this.render();
				}
			}
		}

		if (SLIDING_DRAWER) {
			SLIDING_DRAWER.close("product-info");
			SLIDING_DRAWER.close("products-panel");
		}
	}

	public close(): void {
		if (this.cartElement) {
			this.cartElement.classList.remove("visible");
		}

		if (this.onCloseHandlers) {
			this.onCloseHandlers();
		}
	}

	public onBuy(label: string): void {
		trackEvent("Button", `Cart ${label}`);
	}

	public async addBoardItemsToCart(skus: string[] | ICartItem[]) {
		const items: ICartItem[] = this.getCartItems();

		skus.forEach((sku: string | ICartItem) => {
			const item: ICartItem = {
				sku: "",
				count: 1
			};

			if (typeof sku === "string") {
				item.sku = sku;
			} else {
				item.sku = sku.sku;
				item.count = sku.count;
			}

			const currentProduct = items.find((product) => product.sku === item.sku);
			if (currentProduct) {
				currentProduct.count += item.count;
				items.sort((a, b) => {
					if (a.sku === currentProduct.sku) {
						return -1;
					} else {
						return 0;
					}
				});
			} else {
				items.unshift({
					sku: item.sku,
					count: item.count
				});
			}
		});

		trackEvent("Button", "Add ALL to Cart", `SKUs: ${skus.toString()}`);

		this.wasChanges = true;
		await this.updateCart(items);
		this.checkCartIconView(this.calculateProductsCount());
	}

	public async addToCart(sku: string, count: number = 1) {
		const items: ICartItem[] = this.getCartItems();
		const currentProduct = items.find((product) => product.sku === sku);

		trackEvent("Button", "Add to Cart", `SKU: ${sku}`, count);

		if (currentProduct) {
			currentProduct.count += count;
			items.sort((a, b) => {
				if (a.sku === currentProduct.sku) {
					return -1;
				} else {
					return 0;
				}
			});
		} else {
			items.unshift({
				sku,
				count
			});
		}

		this.wasChanges = true;
		await this.updateCart(items);
		this.checkCartIconView(this.calculateProductsCount());
	}

	public changeBackText(text: string) {
		const icon = document.createElement("div");
		icon.classList.add("cart-close-button-icon");

		const elem = document.querySelector("#cart-close-button") as HTMLDivElement;
		const changedText = `Back to ${text}`;
		const currentText = elem.innerHTML;

		if (changedText !== currentText) {
			elem.innerText = "";
			elem.append(icon);
			elem.append(changedText);
		}
	}

	public checkCartIconView(count: number = 0) {
		if (this.openButton) {
			if (count > 0) {
				this.openButton.classList.add("cart-not-empty");
			} else {
				this.openButton.classList.remove("cart-not-empty");
			}
		}
	}

	public getCartItems(): ICartItem[] | [] {
		return copyObject(this.cartItems);
	}

	private async render() {
		// to organize items in correct order, first - removing already rendered items
		const products = document.querySelectorAll(".product-item");
		if (products.length) {
			products.forEach((product) => {
				if (product.hasAttribute("data-sku")) {
					product.remove();
				}
			});
		}
		await this.getProductsData();
		this.renderBrands();
		this.wasChanges = false;
	}

	private async getProductsData() {
		const currentProducts = this.getCartItems();

		try {
			this.toggleNoProductBlock(false);
			this.toggleLoading(true);

			const products: ICartProduct[] = [];
			const fetchedProducts = await this.getProductsInfo(currentProducts);

			fetchedProducts.forEach((product) => {
				if (product && product.name && product.imageurl && product.originalprice && product.producturl) {
					products.push(product);
				}
			});

			this.setBrands(products);
			this.checkCartIconView(this.calculateProductsCount());
		} catch (e) {
			throw e;
		} finally {
			this.toggleLoading(false);
		}

		this.toggleNoProductBlock(!currentProducts.length);
	}

	private async getProductsInfo(items: ICartItem[]): Promise<ICartProduct[] | []> {
		const products = await getProductsData(items.map((p) => p.sku));
		const sortingOrder = items.map((item) => item.sku);
		products.sort((a, b) => {
			return sortingOrder.indexOf(a.sku) - sortingOrder.indexOf(b.sku);
		});
		const cartProducts: ICartProduct[] = products.map((product) => {
			const existedProduct = this.findProduct(product.sku);
			const current = items.find((item) => item.sku === product.sku);
			let count;
			if (current) {
				count = current.count;
			}
			if (existedProduct) {
				existedProduct.count = count;
				return existedProduct;
			} else {
				return {
					...product,
					count
				};
			}
		});

		return cartProducts;
	}

	private renderBrands() {
		this.sortedBrandsWithProducts.forEach((brand, i, arr) => {
			const [brandName, brandInfo] = brand;
			this.renderBrand(brandName, brandInfo, i + 1, arr.length);
		});
	}

	private renderBrand(brandName: string, brand: IBrandWithProducts, position: number, lastPosition: number) {
		if (!this.brandsContainer) {
			return;
		}

		const existBrandContainer = this.brandsContainer.querySelector<HTMLDivElement>(`[data-brand="${brandName}"]`);
		if (existBrandContainer) {
			if (brand.products.length) {
				const productsContainer = existBrandContainer.querySelector(".products") as HTMLDivElement;
				const buyButton = existBrandContainer.querySelector(".brand-buy-button") as HTMLLinkElement;

				if (+existBrandContainer.style.order !== position) {
					existBrandContainer.style.order = String(position);
				}

				brand.products.forEach((product) => this.renderProduct(product, productsContainer));
				buyButton.href = this.generateBrandCartLink(brand);
			} else {
				existBrandContainer.remove();
			}
		} else if (brand.products.length) {
			const container = document.createElement("div");
			container.setAttribute("data-brand", brandName);
			container.setAttribute("data-position", String(position));
			container.style.order = String(position);
			container.classList.add("brand-item");

			const title = document.createElement("div");
			title.classList.add("brand-title");
			title.innerText = brandName;
			container.append(title);

			const productsContainer = document.createElement("div");
			productsContainer.classList.add("products");
			brand.products.forEach((product) => this.renderProduct(product, productsContainer));
			container.append(productsContainer);

			const buyLabel = `Buy on ${brandName}`;
			const buyButton = document.createElement("a");
			buyButton.classList.add("brand-buy-button", ButtonClasses.PANEL_DEFAULT);
			buyButton.href = this.generateBrandCartLink(brand);
			buyButton.target = "_blank";
			buyButton.innerText = buyLabel;
			buyButton.addEventListener(Listeners.CLICK_EVENT, (event) => {
				this.onBuy(buyLabel);
				const url = buyButton.href;
				if (this.tabs[brandName] && !this.tabs[brandName].closed) {
					this.tabs[brandName].close();
				}
				this.tabs[brandName] = window.open(url, brandName, "menubar = 0");
				event.preventDefault();
			});
			container.append(buyButton);

			if (this.brandsContainer) {
				this.brandsContainer.append(container);
			}
		}
	}

	private renderProduct(product: ICartProduct, wrapper: HTMLElement) {
		const existProduct = wrapper.querySelector(`[data-sku="${product.sku}"]`);

		if (existProduct) {
			const count = existProduct.querySelector(".count") as HTMLDivElement;
			count.innerText = String(product.count);
		} else {
			const container = document.createElement("div");
			container.setAttribute("data-sku", product.sku);
			container.classList.add("product-item");

			const itemInfo = document.createElement("div");
			itemInfo.classList.add("item-info");
			container.append(itemInfo);

			const title = document.createElement("div");
			title.classList.add("product-title");
			title.innerText = product.name;
			itemInfo.append(title);

			const image = document.createElement("div");
			image.classList.add("product-image");
			image.style.background = `center / contain no-repeat url(${product.imageurl})`;
			itemInfo.append(image);

			const textsContainer = document.createElement("div");
			textsContainer.classList.add("product-texts-container");
			itemInfo.append(textsContainer);

			const productInfo = document.createElement("div");
			productInfo.classList.add("product-info-text");
			productInfo.innerText = `Item: #${product.sku}`;
			textsContainer.append(productInfo);

			const productPrice = document.createElement("div");
			productPrice.classList.add("product-price-text");
			productPrice.innerText = `$ ${product.originalprice}`;
			textsContainer.append(productPrice);

			const link = document.createElement("a");
			link.classList.add("product-link");
			link.innerText = "View on website";
			link.href = product.producturl;
			link.target = "_blank";
			link.addEventListener(Listeners.CLICK_EVENT, () => this.viewProduct(product.sku));
			textsContainer.append(link);

			const counterWrapper = document.createElement("div");
			counterWrapper.classList.add("counter-wrapper");
			container.append(counterWrapper);

			const removeButton = document.createElement("div");
			removeButton.classList.add("product-remove-button");
			removeButton.innerText = "remove";
			removeButton.addEventListener(Listeners.CLICK_EVENT, () => this.removeProduct(product.sku));
			counterWrapper.append(removeButton);

			const counter = document.createElement("div");
			counter.classList.add("counter");
			counterWrapper.append(counter);

			const decrementButton = document.createElement("div");
			decrementButton.classList.add("counter-cell", "decrement");
			decrementButton.style.backgroundImage = `url(${this.imagePath}/icn-minus.svg)`;
			decrementButton.addEventListener(Listeners.CLICK_EVENT, () => this.decrementProductCount(product.sku));
			counter.append(decrementButton);

			const count = document.createElement("div");
			count.classList.add("counter-cell", "count");
			count.innerText = String(product.count);
			counter.append(count);

			const incrementButton = document.createElement("div");
			incrementButton.classList.add("counter-cell", "increment");
			incrementButton.style.backgroundImage = `url(${this.imagePath}/icn-plus.svg)`;
			incrementButton.addEventListener(Listeners.CLICK_EVENT, () => this.incrementProductCount(product.sku));
			counter.append(incrementButton);

			wrapper.append(container);
		}
	}

	private generateBrandCartLink(brand: IBrandWithProducts): string {
		return `${brand.cartUrl}${brand.products.reduce(
			(acc, product) => `${acc ? acc + "," : ""}${product.count}x${product.sku}`,
			""
		)}`;
	}

	private changeBrandCartLink(brandName: string) {
		const brand = this.brandWithProducts[brandName];

		if (brand && this.cartElement) {
			const brandLink = this.cartElement.querySelector(
				`[data-brand="${brandName}"] .brand-buy-button`
			) as HTMLLinkElement;
			brandLink.href = this.generateBrandCartLink(brand);
		}
	}

	private getBrandsSortedArray(brandsWithProducts: ICartBrands) {
		return Object.entries(brandsWithProducts).sort((firstBrand, secondBrand) => {
			const firstBrandProductsCount = this.calculateBrandProductsCount(firstBrand[1].products);
			const secondBrandProductsCount = this.calculateBrandProductsCount(secondBrand[1].products);

			if (firstBrandProductsCount < secondBrandProductsCount) {
				return 1;
			} else if (firstBrandProductsCount > secondBrandProductsCount) {
				return -1;
			} else {
				return firstBrand[0] > secondBrand[0] ? 1 : -1;
			}
		});
	}

	private getBrandsWithProducts(brands: string[], products: ICartProduct[]) {
		return brands.reduce((total, brand) => {
			const brandFromDB = this.brands.find((item) => item.name === brand);
			return {
				...total,
				[brand]: {
					cartUrl: brandFromDB ? brandFromDB.cartUrl : "",
					products: products.filter((product) => product.brand === brand)
				}
			};
		}, {}) as ICartBrands;
	}

	private removeProductElement(sku: string) {
		const cartProduct = this.findProduct(sku);
		if (this.cartElement) {
			const renderedProduct = this.cartElement.querySelector(`[data-sku="${sku}"]`) as HTMLDivElement;
			renderedProduct.remove();
		}

		if (cartProduct && this.brandsContainer) {
			const brandProducts = this.brandWithProducts[cartProduct.brand].products;
			this.brandWithProducts[cartProduct.brand].products = brandProducts.filter((product) => product.sku !== sku);
			if (!this.brandWithProducts[cartProduct.brand].products.length) {
				const brandElem = this.brandsContainer.querySelector(`[data-brand="${cartProduct.brand}"]`);
				if (brandElem) {
					brandElem.remove();
				}
			} else {
				this.changeBrandCartLink(cartProduct.brand);
			}
		}
	}

	private async removeProduct(sku: string) {
		trackEvent("Button", "Cart Remove", `SKU: ${sku}`);
		const currentProducts = this.getCartItems().filter((product) => product.sku !== sku);
		this.removeProductElement(sku);
		this.toggleNoProductBlock(!currentProducts.length);
		await this.updateCart(currentProducts);
		this.checkCartIconView(this.calculateProductsCount());
	}

	private viewProduct(sku: string) {
		trackEvent("Button", "Cart View on Website", `SKU: ${sku}`);
	}

	private setBrands(products: ICartProduct[]) {
		const brands = Array.from(new Set(products.map((product) => product.brand)));
		const newBrandsWithProducts = this.getBrandsWithProducts(brands, products);
		this.brandWithProducts = newBrandsWithProducts;
		this.sortedBrandsWithProducts = this.getBrandsSortedArray(newBrandsWithProducts);
	}

	private toggleLoading(isLoading: boolean) {
		if (this.brandsContainer && this.skeletonLoader) {
			if (isLoading) {
				this.skeletonLoader.classList.remove("owHidden");
				this.brandsContainer.classList.add("owHidden");
			} else {
				this.skeletonLoader.classList.add("owHidden");
				this.brandsContainer.classList.remove("owHidden");
			}
		}
	}

	private incrementProductCount(sku: string) {
		trackEvent("Button", "Cart Increment", `SKU: ${sku}`);
		this.changeProductCount(sku, 1);
	}

	private decrementProductCount(sku: string) {
		trackEvent("Button", "Cart Decrement", `SKU: ${sku}`);
		this.changeProductCount(sku, -1);
	}

	private calculateProductsCount() {
		return [...this.getCartItems()].reduce((total, item) => total + item.count, 0);
	}

	private calculateBrandProductsCount(products: ICartProduct[]) {
		return products.reduce((total, item) => total + item.count, 0);
	}

	private changeProductCount(sku: string, changes: number) {
		if (!this.cartElement) {
			return;
		}

		const renderedProduct = this.cartElement.querySelector(`[data-sku="${sku}"]`) as HTMLDivElement;
		const countElem = renderedProduct.querySelector(".count") as HTMLDivElement;
		const currentProducts = this.getCartItems();
		const currentProduct = currentProducts.find((item) => item.sku === sku);

		if (currentProduct) {
			currentProducts.sort((a, b) => {
				if (a.sku === currentProduct.sku) {
					return -1;
				} else {
					return 1;
				}
			});
			const productData = this.findProduct(currentProduct.sku);
			currentProduct.count += changes;
			this.checkCartIconView(this.calculateProductsCount());
			countElem.innerText = String(currentProduct.count);
			if (productData) {
				productData.count += changes;
				this.changeBrandCartLink(productData.brand);
			}
		}

		if (currentProduct && currentProduct.count <= 0) {
			this.removeProduct(currentProduct.sku);
		} else {
			this.updateCart(currentProducts);
		}

		this.wasChanges = true;
	}

	private async updateCart(products: ICartItem[]) {
		this.disableActionSelectors();
		this.toggleCartLoadingIcon(true);
		this.cartItems = await updateCartData(this.customerId, this.isGuest, products);
		this.enableActionSelectors();
		this.toggleCartLoadingIcon(false);
	}

	private disableActionSelectors() {
		CART_ACTION_SELECTORS.forEach((selector) => {
			Array.from(document.querySelectorAll(selector)).forEach((el) => el.classList.add("disabled"));
		});
	}

	private enableActionSelectors() {
		CART_ACTION_SELECTORS.forEach((selector) => {
			Array.from(document.querySelectorAll(selector)).forEach((el) => el.classList.remove("disabled"));
		});
	}

	private toggleCartLoadingIcon(show: boolean) {
		if (this.openButton) {
			if (show) {
				this.openButton.classList.add("show-loading");
			} else {
				this.openButton.classList.remove("show-loading");
			}
		}
	}

	private findProduct(sku: string) {
		return Object.values(this.brandWithProducts)
			.reduce((all: ICartProduct[], item) => [...all, ...item.products], [])
			.find((product) => product.sku === sku);
	}

	private toggleNoProductBlock(show: boolean) {
		if (this.brandsContainer && this.noProductsBlock) {
			if (show) {
				this.noProductsBlock.classList.remove("owHidden");
				this.brandsContainer.classList.add("owHidden");
			} else {
				this.noProductsBlock.classList.add("owHidden");
				this.brandsContainer.classList.remove("owHidden");
			}
		}
	}

	private onUnload() {
		Object.keys(this.tabs).forEach((brandName: string) => {
			if (this.tabs[brandName] && !this.tabs[brandName].closed) {
				this.tabs[brandName].close();
			}
			delete this.tabs[brandName];
		});
	}

	private hookEvents() {
		if (this.openButton) {
			this.openButton.addEventListener(Listeners.CLICK_EVENT, this.open.bind(this));
		}
		if (this.closeButton) {
			this.closeButton.addEventListener(Listeners.CLICK_EVENT, this.close.bind(this));
		}
		window.addEventListener(Listeners.BEFORE_UNLOAD_EVENT, this.onUnload.bind(this));
	}
}
