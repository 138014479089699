import { Unit } from "../enumerations/unit";
import { Listeners } from "../enumerations/web";
import { ISettings } from "../interfaces/boardInterfaces";
import { IKeyValuePair } from "../models/configModel";
import { getAjaxJSON, postAjaxJSON } from "../utils/ajax";
import { AddAllBoardDialog } from "./addAllBoardDialog";
import { AddNotificationBanner } from "./addNotificationBanner";
import { CartController } from "./cart";
import { CreateBoardDialog } from "./createBoardDialog";
import { DeleteBoardDialog } from "./deleteBoardDialog";
import { SlidingDrawer } from "./editor/slidingDrawer";
import { GuestAlertDialog } from "./guestAlertDialog";
import { GuestImportDialog } from "./guestImportDialog";
import { RenameBoardDialog } from "./renameBoardDialog";
import { SignOutWarning } from "./signOutWarning";

export class HamburgerMenu {
	public static readonly HAMBURGER_TOGGLE_SELECTOR = "#hamburgerToggle";
	public static readonly HAMBURGER_MENU_CONTENT_SELECTOR = "#hamburger-menu .hamburger-menu-content";
	public static readonly HAMBURGER_MENU_CHECKED_SELECTOR = ".hamburger-checked";
	public static readonly HAMBURGER_MENU_BLOCKER_SELECTOR = "#hamburger-blocker";
	public static readonly HAMBURGER_MENU_SETTINGS_SELECTOR = ".hamburger-menu-content .settings-menu-option";
	public static readonly CREATE_NEW_BOARD_LINK_SELECTOR = "#hamburger-menu .create-new-board-menu-option";
	public static readonly MY_BOARDS_LINK_SELECTOR = "#hamburger-menu .my-boards-menu-option";
	public static readonly SETTINGS_LINK_SELECTOR = "#hamburger-menu .settings-menu-option";
	public static readonly MENU_SETTINGS_SELECTOR = "#hamburger-menu .menu-settings";
	public static readonly SIGN_OUT_LINK_SELECTOR = "#hamburger-menu .sign-out-menu-option";
	public static readonly BACK_BUTTON_SELECTOR = "#hamburger-menu .menu-settings .back-link img";
	public static readonly SETTINGS_SAVE_MEASUREMENT_BTN = "#save-settings-btn";
	public static readonly SETTINGS_CANCEL_MEASUREMENT_BTN = "#cancel-settings-btn";
	public static readonly MEASURE_SELECT_DROPDOWN_SELECTOR = ".measurement-settings-select select[name='system']";
	public static readonly SETTINGS_MODAL = "#measurement-settings-modal";

	private hamburgerToggle?: HTMLElement;
	private hamburgerMenuChecked!: HTMLInputElement;
	private hamburgerMenuContent?: HTMLElement;
	private hamburgerMenuBlocker?: HTMLDivElement;
	private createNewBoardLink?: HTMLElement;
	private myBoardsLink?: HTMLElement;
	private settingsLink?: HTMLElement;
	private menuSettings?: HTMLElement;
	private signOutLink?: HTMLElement;
	private backBtn?: HTMLElement;
	private signOutWarning!: SignOutWarning;
	private saveSettingsBtn?: HTMLElement;
	private cancelSettingsBtn?: HTMLElement;
	private measureSelectDropdown?: HTMLSelectElement;
	private settingsModal?: HTMLElement;

	public init() {
		this.hamburgerToggle = document.querySelector(HamburgerMenu.HAMBURGER_TOGGLE_SELECTOR) as HTMLElement;
		this.hamburgerMenuChecked = document.querySelector(
			HamburgerMenu.HAMBURGER_MENU_CHECKED_SELECTOR
		) as HTMLInputElement;
		this.hamburgerMenuContent = document.querySelector(
			HamburgerMenu.HAMBURGER_MENU_CONTENT_SELECTOR
		) as HTMLElement;
		this.hamburgerMenuBlocker = document.querySelector(
			HamburgerMenu.HAMBURGER_MENU_BLOCKER_SELECTOR
		) as HTMLDivElement;
		this.createNewBoardLink = document.querySelector(HamburgerMenu.CREATE_NEW_BOARD_LINK_SELECTOR) as HTMLElement;
		this.myBoardsLink = document.querySelector(HamburgerMenu.MY_BOARDS_LINK_SELECTOR) as HTMLElement;
		this.settingsLink = document.querySelector(HamburgerMenu.SETTINGS_LINK_SELECTOR) as HTMLElement;
		this.menuSettings = document.querySelector(HamburgerMenu.MENU_SETTINGS_SELECTOR) as HTMLElement;
		this.signOutLink = document.querySelector(HamburgerMenu.SIGN_OUT_LINK_SELECTOR) as HTMLElement;
		this.backBtn = document.querySelector(HamburgerMenu.BACK_BUTTON_SELECTOR) as HTMLElement;
		this.signOutWarning = new SignOutWarning();
		this.saveSettingsBtn = document.querySelector(HamburgerMenu.SETTINGS_SAVE_MEASUREMENT_BTN) as HTMLElement;
		this.cancelSettingsBtn = document.querySelector(HamburgerMenu.SETTINGS_CANCEL_MEASUREMENT_BTN) as HTMLElement;
		this.measureSelectDropdown = document.querySelector(
			HamburgerMenu.MEASURE_SELECT_DROPDOWN_SELECTOR
		) as HTMLSelectElement;
		this.settingsModal = document.querySelector(HamburgerMenu.SETTINGS_MODAL) as HTMLElement;

		this.hookEvents();
	}

	public onCreateNewBoard(event: MouseEvent) {
		this.closeHamburgerMenu(event);
		CREATE_BOARD_DIALOG.onShow(event);
	}

	public onShowMyBoards(event: MouseEvent) {
		this.closeHamburgerMenu(event);
		window.location.href = "/portfolio";
	}

	public showSettings(event: MouseEvent) {
		this.hideHamburgerMenuContent();
		if (this.menuSettings && this.menuSettings.classList.contains("owHidden")) {
			this.menuSettings.classList.remove("owHidden");
		}
	}

	public hideSettings() {
		if (this.menuSettings && !this.menuSettings.classList.contains("owHidden")) {
			this.menuSettings.classList.add("owHidden");
		}
	}

	public hideHamburgerMenuContent() {
		if (this.hamburgerMenuContent) {
			this.hamburgerMenuContent.classList.add("owHidden");
		}
	}

	public onToggleMenu(event: MouseEvent) {
		if (this.hamburgerMenuChecked) {
			if (this.hamburgerMenuChecked.checked) {
				this.showHamburgerMenu(event);
			}
		}
	}

	// this allows the hamburger menu to be closed when clicking outside of it
	public onClickOutsideHamburgerMenu(event: MouseEvent) {
		if (this.hamburgerToggle && !this.hamburgerToggle.contains(event.target as Node)) {
			this.closeHamburgerMenu(event);
		}
	}

	public showHamburgerMenu(event: MouseEvent) {
		if (this.hamburgerMenuChecked && !this.hamburgerMenuChecked.checked) {
			this.hamburgerMenuChecked.checked = true;
		}

		this.showHamburgerMenuContent(event);
	}

	// the opening and closing of the hamburger menu depends if the input has been checked/unchecked
	public closeHamburgerMenu(event: MouseEvent) {
		if (this.hamburgerMenuChecked && this.hamburgerMenuChecked.checked) {
			this.hamburgerMenuChecked.checked = false;
		}
	}

	// this runs when you click on back arrow on the settings panel
	public showHamburgerMenuContent(event: MouseEvent) {
		this.hideSettings();
		if (this.hamburgerMenuContent && this.hamburgerMenuContent.classList.contains("owHidden")) {
			this.hamburgerMenuContent.classList.remove("owHidden");
		}
	}

	public signOut(event: MouseEvent) {
		this.closeHamburgerMenu(event);
		this.signOutWarning.show();
	}

	private hookEvents() {
		if (this.hamburgerMenuChecked) {
			this.hamburgerMenuChecked.addEventListener(Listeners.CLICK_EVENT, this.onToggleMenu.bind(this));
		}

		if (this.createNewBoardLink) {
			this.createNewBoardLink.addEventListener(Listeners.CLICK_EVENT, this.onCreateNewBoard.bind(this));
		}

		if (this.myBoardsLink) {
			this.myBoardsLink.addEventListener(Listeners.CLICK_EVENT, this.onShowMyBoards.bind(this));
		}

		if (this.settingsLink) {
			this.settingsLink.addEventListener(Listeners.CLICK_EVENT, (event: MouseEvent) => {
				this.getSettings().then((settings: ISettings) => {
					if (this.measureSelectDropdown) {
						this.measureSelectDropdown.value = settings.unit;
					}
				});
				this.showSettings(event);
			});
		}

		if (this.signOutLink) {
			this.signOutLink.addEventListener(Listeners.CLICK_EVENT, this.signOut.bind(this));
		}

		if (this.backBtn) {
			this.backBtn.addEventListener(Listeners.CLICK_EVENT, this.showHamburgerMenuContent.bind(this));
		}

		if (this.saveSettingsBtn) {
			this.saveSettingsBtn.addEventListener(Listeners.CLICK_EVENT, (event: MouseEvent) => {
				event.preventDefault();

				if (this.measureSelectDropdown) {
					const system = this.measureSelectDropdown.options[this.measureSelectDropdown.selectedIndex].value;

					this.saveSettings({
						unit: system
					});
				}
				this.closeHamburgerMenu(event);
			});
		}

		if (this.settingsModal) {
			this.settingsModal.addEventListener(Listeners.SUBMIT_EVENT, (event) => {
				event.preventDefault();

				let system = Unit.AMERICAN as string;
				if (this.measureSelectDropdown) {
					system = this.measureSelectDropdown.options[this.measureSelectDropdown.selectedIndex].value;
				}
				this.saveSettings({
					unit: system
				});
			});
		}

		if (this.cancelSettingsBtn) {
			this.cancelSettingsBtn.addEventListener(Listeners.CLICK_EVENT, this.closeHamburgerMenu.bind(this));
		}

		if (this.hamburgerMenuBlocker) {
			this.hamburgerMenuBlocker.addEventListener(Listeners.CLICK_EVENT, this.closeHamburgerMenu.bind(this));
		}

		window.addEventListener(Listeners.CLICK_EVENT, this.onClickOutsideHamburgerMenu.bind(this));
	}

	private async getSettings(): Promise<ISettings> {
		try {
			const settings: ISettings = await getAjaxJSON("/settings/retrieve");

			return settings;
		} catch (err) {
			return {
				unit: ""
			};
		}
	}

	private async saveSettings(settings: ISettings): Promise<boolean> {
		try {
			await postAjaxJSON<IKeyValuePair>("/settings/save", settings);

			return true;
		} catch (err) {
			return false;
		}
	}
}

(window as any).BOARDS_INITIALIZED = false;
(window as any).CREATE_BOARD_DIALOG = new CreateBoardDialog();
(window as any).RENAME_BOARD_DIALOG = new RenameBoardDialog();
(window as any).DELETE_BOARD_DIALOG = new DeleteBoardDialog();
(window as any).GUEST_ALERT_DIALOG = new GuestAlertDialog();
(window as any).GUEST_IMPORT_DIALOG = new GuestImportDialog();
(window as any).ADD_ALL_BOARD_DIALOG = new AddAllBoardDialog();
(window as any).SLIDING_DRAWER = new SlidingDrawer();
(window as any).CART_CONTROLLER = new CartController();
(window as any).ADD_NOTIFICATION_BANNER = new AddNotificationBanner();

declare let BOARDS_INITIALIZED: boolean;
declare const CREATE_BOARD_DIALOG: CreateBoardDialog;
declare const RENAME_BOARD_DIALOG: RenameBoardDialog;
declare const DELETE_BOARD_DIALOG: DeleteBoardDialog;
declare const GUEST_ALERT_DIALOG: GuestAlertDialog;
declare const GUEST_IMPORT_DIALOG: GuestImportDialog;
declare const ADD_ALL_BOARD_DIALOG: AddAllBoardDialog;
declare const CART_CONTROLLER: CartController;
declare const ADD_NOTIFICATION_BANNER: AddNotificationBanner;

window.addEventListener(Listeners.LOAD_EVENT, () => {
	// prevents multiple initialization
	// TODO - resolve the reason of multiple initialization
	if (!BOARDS_INITIALIZED) {
		CREATE_BOARD_DIALOG.init();
		RENAME_BOARD_DIALOG.init();
		DELETE_BOARD_DIALOG.init();
		GUEST_ALERT_DIALOG.init();
		GUEST_IMPORT_DIALOG.init();
		ADD_ALL_BOARD_DIALOG.init();
		CART_CONTROLLER.init();
		ADD_NOTIFICATION_BANNER.init();
		BOARDS_INITIALIZED = true;
	}
});
