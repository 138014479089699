export function trackEvent(category: string, action: string, name: string = "", value: number = 0): void {
	const _paq: any[] = (window as any)._paq;
	const args: any[] = ["trackEvent", category, action];
	if (name) {
		args.push(name);
	}
	if (value) {
		args.push(value);
	}
	if (_paq) {
		_paq.push(args);
	}

	// tslint:disable-next-line:no-console
	console.log(args.join(" - "));
}
