import { IBrand } from "../interfaces/brandInterfaces";
import { IFabric, IProduct, IProductResult, ISuggestion, ISuggestionResult } from "../interfaces/productInterfaces";
import { postAjaxJSON } from "./ajax";
import { copyObject } from "./obj";

const productCache: { [sku: string]: IProduct } = {};

export async function getProductData(sku: string, attempt: number = 1): Promise<IProduct | null> {
	if (productCache[sku]) {
		return productCache[sku];
	}

	try {
		const productResult = await postAjaxJSON<IProductResult>("/data/product", {
			sku
		});

		if (productResult && productResult.total > 0 && productResult.data.length > 0) {
			productCache[sku] = productResult.data[0];
			return productResult.data[0];
		}
	} catch (err) {
		if (attempt < 3) {
			return await getProductData(sku, attempt + 1);
		}
	}
	return null;
}

export async function getProductsData(skus: string[], attempt: number = 1): Promise<IProduct[]> {
	const cached: IProduct[] = [];
	const fetchSkus = skus.filter(
		(sku: string): boolean => {
			if (productCache[sku]) {
				cached.push(productCache[sku]);
				return false;
			}
			return true;
		}
	);

	// If all products are cached, just return our cache list.
	if (!fetchSkus.length) {
		return cached;
	}

	const fetchBatches = async (batch: string[], offset: number): Promise<IProduct[]> => {
		try {
			const productResult = await postAjaxJSON<IProductResult>("/data/products", {
				skus: batch,
				offset,
				limit: 100
			});

			if (productResult && productResult.total > 0 && productResult.data.length > 0) {
				productResult.data.forEach((product: IProduct) => {
					productCache[product.sku] = product;
				});
				let partialResult = productResult.data;
				const count = productResult.offset + productResult.data.length;
				if (productResult.total > count) {
					partialResult = partialResult.concat(await fetchBatches(batch, count));
				}
				return partialResult;
			}
		} catch (err) {
			if (attempt < 3) {
				return await getProductsData(skus, attempt + 1);
			}
		}
		return [];
	};

	const result = await fetchBatches(fetchSkus, 0);
	return result.concat(cached);
}

export async function getFabrics(sku: string, offset: number = 0): Promise<IFabric[]> {
	const productList = await postAjaxJSON<IProductResult>("/data/fabrics", {
		sku,
		offset
	})
		.then((productResult: IProductResult) => {
			if (productResult && productResult.total > 0 && productResult.data.length > 0) {
				return productResult.data;
			}
			return [] as IProduct[];
		})
		.catch((err) => {
			return [] as IProduct[];
		});

	const brands = await postAjaxJSON<IBrand[]>("/data/brands", {});

	const fabricSets = {};
	["ow_fabric", "ow_finish1", "ow_finish2", "ow_finish3"].forEach(
		(key: string): void => {
			const keySet = new Set(
				productList.map((product: IProduct) => {
					return product[key];
				})
			);
			keySet.delete(null);
			keySet.delete("None");
			fabricSets[key] = keySet;
		}
	);

	return productList.map(
		(product: IProduct): IFabric => {
			let fabricPath: string = "";
			brands.forEach(
				(brand: IBrand): void => {
					if (brand.name === product.brand) {
						fabricPath = brand.path;
					}
				}
			);

			const minFabricSetSize = productList.length === 1 ? 1 : 2;
			let fabricName: string = "";
			["ow_fabric", "ow_finish1", "ow_finish2", "ow_finish3"].forEach(
				(key: string): void => {
					if (!fabricName && fabricSets[key].size >= minFabricSetSize) {
						fabricName = `${product[key]}.jpg`;
					}
				}
			);

			return {
				sku: product.sku,
				oman: product.ow_oman,
				name: `${product.ow_fabric || "None"}_${product.ow_finish1 || "None"}_${product.ow_finish2 ||
					"None"}_${product.ow_finish3 || "None"}`,
				thumbnail: [fabricPath, "Icons", "Fabrics", fabricName].join("/")
			};
		}
	);
}

export async function getSuggestions(skus: string[], roomType?: string, similarTo?: string): Promise<ISuggestion[]> {
	return await postAjaxJSON<ISuggestionResult>("/data/suggestions", {
		skus,
		roomType,
		similarTo
	})
		.then((suggestionResult: ISuggestionResult) => {
			if (suggestionResult && suggestionResult.suggestions.length > 0) {
				return suggestionResult.suggestions as ISuggestion[];
			}
			return [] as ISuggestion[];
		})
		.catch((err) => {
			return [] as ISuggestion[];
		});
}

export async function getBrands() {
	return await postAjaxJSON<IBrand[]>("/data/brands", {})
		.then((brands) => {
			return brands;
		})
		.catch((err) => {
			return [];
		});
}

export function compareSkus(skusA: string[], skusB: string[]): boolean {
	if (skusA.length !== skusB.length) {
		return false;
	}
	const skusC = copyObject(skusB);
	skusA.forEach((sku: string) => {
		const foundIndex = skusC.indexOf(sku);
		if (foundIndex > -1) {
			skusC.splice(foundIndex, 1);
		}
	});

	return skusC.length === 0;
}
