import { Listeners } from "../../enumerations/web";

const DRAWER_SIZE_MIN = 100;

interface IScrolls {
	[key: string]: boolean;
}

interface IScrollValues {
	[key: string]: number;
}

export class SlidingDrawer {
	public drawersWithBlockedEvents: string[] = [];
	public isScrolling: IScrolls = {};
	public scrollTopValues: IScrollValues = {};

	private touchDownContent = false;

	private onOpenHandlers: { [id: string]: () => void } = {};
	private onCloseHandlers: { [id: string]: () => void } = {};
	private drawerElem?: HTMLDivElement;
	private sizeOrigin: number = 0;

	private onMouseMoveSizerBinding?: (this: HTMLElement, ev: MouseEvent) => any;
	private onMouseUpSizerBinding?: (this: HTMLElement, ev: MouseEvent) => any;

	private onTouchMoveSizerBinding?: (this: HTMLElement, ev: TouchEvent) => any;
	private onTouchEndSizerBinding?: (this: HTMLElement, ev: TouchEvent) => any;

	public init(id: string, onOpenHandler?: () => void, onCloseHandler?: () => void): void {
		const drawerElem = document.querySelector(`#${id}`) as HTMLDivElement;
		if (!drawerElem) {
			return;
		}

		if (onOpenHandler) {
			this.onOpenHandlers[id] = onOpenHandler;
		}

		if (onCloseHandler) {
			this.onCloseHandlers[id] = onCloseHandler;
		}

		const contentElem = drawerElem.querySelector(".sliding-drawer-contents") as HTMLDivElement;

		if (contentElem) {
			contentElem.addEventListener(Listeners.MOUSE_DOWN_EVENT, this.onDownContent.bind(this));
			contentElem.addEventListener(Listeners.MOUSE_UP_EVENT, this.onUpContent.bind(this));
			contentElem.addEventListener(Listeners.TOUCH_START_EVENT, this.onTouchBlocker.bind(this));
			contentElem.addEventListener(Listeners.TOUCH_END_EVENT, this.onUpContent.bind(this));
		}

		drawerElem.addEventListener(Listeners.MOUSE_DOWN_EVENT, this.onMouseDownSizer.bind(this));
		drawerElem.addEventListener(Listeners.TOUCH_START_EVENT, this.onTouchStartSizer.bind(this));
	}

	public isOpen(id: string): boolean {
		const drawerElem = document.querySelector(`#${id}`) as HTMLDivElement;
		if (drawerElem) {
			return drawerElem.classList.contains("visible");
		}
		return false;
	}

	public open(id: string): void {
		const drawerElem = document.querySelector(`#${id}`) as HTMLDivElement;
		if (drawerElem) {
			drawerElem.classList.add("visible");
			this.makeTop(id);

			if (this.onOpenHandlers[id]) {
				this.onOpenHandlers[id]();
			}
		}
	}

	public close(id: string): void {
		const drawerElem = document.querySelector(`#${id}`) as HTMLDivElement;
		if (drawerElem) {
			drawerElem.classList.remove("visible");

			if (this.onCloseHandlers[id]) {
				this.onCloseHandlers[id]();
			}
		}
	}

	public listenForScroll(id: string, targetSelector: string) {
		const target = document.querySelector(targetSelector);
		if (target) {
			target.addEventListener(Listeners.TOUCH_START_EVENT, (e) => this.onStartScroll(e, id));
			target.addEventListener(Listeners.TOUCH_MOVE_EVENT, (e) => this.onScrolling(e, id));
			target.addEventListener(Listeners.TOUCH_END_EVENT, () => this.onEndScroll(id));
		}
	}

	public isContentScrolling(id: string) {
		return this.isScrolling[id];
	}

	public blockEventsForPanel(id: string) {
		this.drawersWithBlockedEvents.push(id);
		const panelContent = document.querySelector(`#${id} .sliding-drawer-contents`);
		if (panelContent) {
			panelContent.classList.add("owDisableScrollForAll");
		}
	}

	public unblockEventsForPanel(id: string) {
		this.drawersWithBlockedEvents = this.drawersWithBlockedEvents.filter((panelId) => panelId !== id);
		const panelContent = document.querySelector(`#${id} .sliding-drawer-contents`);
		if (panelContent) {
			panelContent.classList.remove("owDisableScrollForAll");
		}
	}

	private onDownContent(event: MouseEvent | TouchEvent): void {
		this.touchDownContent = true;
	}

	private onStartScroll(event: Event, id: string) {
		const target = event.currentTarget as HTMLDivElement;
		this.scrollTopValues[id] = target.scrollTop;
	}

	private onScrolling(event: Event, id: string) {
		const target = event.currentTarget as HTMLDivElement;
		const scrollTop = target.scrollTop;
		const diffY = Math.abs(this.scrollTopValues[id] - scrollTop);
		this.isScrolling[id] = diffY > 50;
	}

	private onEndScroll(id: string) {
		this.isScrolling[id] = false;
	}

	private onUpContent(event: MouseEvent | TouchEvent) {
		this.touchDownContent = false;
	}

	private isEventBlocked() {
		return this.drawerElem && this.drawersWithBlockedEvents.includes(this.drawerElem.id);
	}

	private onMouseDownSizer(event: MouseEvent): void {
		if (!this.isEventBlocked() && !this.touchDownContent) {
			this.drawerElem = (event.currentTarget as any) as HTMLDivElement;

			if (!this.drawerElem) {
				return;
			}

			this.sizeOrigin = event.pageY;

			this.onMouseMoveSizerBinding = this.onMouseMoveSizer.bind(this);
			this.onMouseUpSizerBinding = this.onMouseUpSizer.bind(this);

			if (this.onMouseMoveSizerBinding) {
				document.body.addEventListener(Listeners.MOUSE_MOVE_EVENT, this.onMouseMoveSizerBinding);
			}
			if (this.onMouseUpSizerBinding) {
				document.body.addEventListener(Listeners.MOUSE_UP_EVENT, this.onMouseUpSizerBinding);
			}
		}
	}

	private onMouseUpSizer(event: MouseEvent): void {
		if (this.isEventBlocked && !this.isEventBlocked()) {
			if (this.onMouseMoveSizerBinding) {
				document.body.removeEventListener(Listeners.MOUSE_MOVE_EVENT, this.onMouseMoveSizerBinding);
				delete this.onMouseMoveSizerBinding;
			}
			if (this.onMouseUpSizerBinding) {
				document.body.removeEventListener(Listeners.MOUSE_UP_EVENT, this.onMouseUpSizerBinding);
				delete this.onMouseUpSizerBinding;
			}
		}
	}

	private onMouseMoveSizer(event: MouseEvent): void {
		if (this.isEventBlocked && !this.isEventBlocked() && !this.touchDownContent) {
			const pos = event.pageY;
			if (this.drawerElem) {
				const delta = this.sizeOrigin - pos;
				this.drawerElem.style.height = `${Math.max(
					DRAWER_SIZE_MIN,
					Math.min(window.innerHeight, this.drawerElem.clientHeight + delta)
				)}px`;
				this.sizeOrigin = pos;
			}
		}
	}

	private onTouchBlocker(event: TouchEvent): void {
		if (this.onTouchMoveSizerBinding) {
			document.body.removeEventListener(Listeners.TOUCH_MOVE_EVENT, this.onTouchMoveSizerBinding);
			delete this.onTouchMoveSizerBinding;
		}
		if (this.onTouchEndSizerBinding) {
			document.body.removeEventListener(Listeners.TOUCH_END_EVENT, this.onTouchEndSizerBinding);
			delete this.onTouchEndSizerBinding;
		}
		this.touchDownContent = true;
	}

	private onTouchStartSizer(event: TouchEvent): void {
		if (!this.isEventBlocked() || !this.touchDownContent) {
			this.drawerElem = (event.currentTarget as any) as HTMLDivElement;
			if (!this.drawerElem) {
				return;
			}

			if (event.touches.length) {
				this.sizeOrigin = event.touches[0].pageY;

				this.onTouchMoveSizerBinding = this.onTouchMoveSizer.bind(this);
				this.onTouchEndSizerBinding = this.onTouchEndSizer.bind(this);

				if (this.onTouchMoveSizerBinding) {
					document.body.addEventListener(Listeners.TOUCH_MOVE_EVENT, this.onTouchMoveSizerBinding);
				}
				if (this.onTouchEndSizerBinding) {
					document.body.addEventListener(Listeners.TOUCH_END_EVENT, this.onTouchEndSizerBinding);
				}
			}
		}
	}

	private onTouchEndSizer(event: TouchEvent): void {
		if (this.onTouchMoveSizerBinding) {
			document.body.removeEventListener(Listeners.TOUCH_MOVE_EVENT, this.onTouchMoveSizerBinding);
			delete this.onTouchMoveSizerBinding;
		}
		if (this.onTouchEndSizerBinding) {
			document.body.removeEventListener(Listeners.TOUCH_END_EVENT, this.onTouchEndSizerBinding);
			delete this.onTouchEndSizerBinding;
		}
	}

	private onTouchMoveSizer(event: TouchEvent): void {
		if (!this.isEventBlocked() && !this.touchDownContent) {
			if (event.touches.length) {
				const pos = event.touches[0].pageY;
				if (this.drawerElem) {
					const delta = this.sizeOrigin - pos;
					this.drawerElem.style.height = `${Math.max(
						DRAWER_SIZE_MIN,
						Math.min(window.innerHeight, this.drawerElem.clientHeight + delta)
					)}px`;
					this.sizeOrigin = pos;
				}
			}
		}
	}

	private makeTop(id: string): void {
		const currentTop = document.querySelector(".top-panel") as HTMLDivElement;
		if (currentTop) {
			currentTop.classList.remove("top-panel");
		}
		const drawerElem = document.querySelector(`#${id}`) as HTMLDivElement;
		if (drawerElem) {
			drawerElem.classList.add("top-panel");
		}
	}
}
