import { LocalStorageKeys } from "../enumerations/utils";
import { ICartItem } from "../interfaces/productInterfaces";
import { postAjaxJSON } from "./ajax";
import { getLocalStorage, setLocalStorage } from "./storage";

export function formatCartRequestContent(content: ICartItem[]): string {
	const result = {};
	content.forEach((item) => {
		result[" " + item.sku] = item.count;
	});
	return JSON.stringify(result);
}

export function formatCartResponseString(response: string): ICartItem[] {
	const cartObject: {} = JSON.parse(response);
	const result: ICartItem[] = [];
	for (const field of Object.keys(cartObject)) {
		result.push({
			sku: field.trim(),
			count: cartObject[field]
		});
	}
	return result;
}

export async function getCartData(customerId: number): Promise<ICartItem[]> {
	return await postAjaxJSON<ICartItem[]>("/data/cart", { customerId })
		.then((cartItems) => {
			return cartItems;
		})
		.catch((err) => {
			return [];
		});
}

export async function getGuestCartData(): Promise<ICartItem[]> {
	try {
		return JSON.parse(getLocalStorage(LocalStorageKeys.GUEST_CART, ""));
	} catch (err) {
		return [];
	}
}

export async function updateCartData(customerId: number, isGuest: boolean, content: ICartItem[]): Promise<ICartItem[]> {
	if (isGuest) {
		try {
			setLocalStorage(LocalStorageKeys.GUEST_CART, JSON.stringify(content));
			return content;
		} catch (err) {
			return [];
		}
	} else {
		return await postAjaxJSON<ICartItem[]>("/data/cart/update", {
			content,
			customerId
		})
			.then((cartItems) => {
				return cartItems;
			})
			.catch((err) => {
				return [];
			});
	}
}

export async function clearGuestCartData(): Promise<void> {
	try {
		return setLocalStorage(LocalStorageKeys.GUEST_CART, "");
	} catch (err) {
		// tslint:disable-next-line:no-console
		console.error(err.stack);
	}
}
