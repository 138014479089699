import { Listeners } from "../enumerations/web";
import { IBoard } from "../interfaces/boardInterfaces";
import { postAjaxJSON } from "../utils/ajax";
import { trackEvent } from "../utils/tracking";

export class RenameBoardDialog {
	private backgroundBlockerElem?: HTMLDivElement;
	private containerElem?: HTMLDivElement;
	private inputElem?: HTMLInputElement;
	private errorElem?: HTMLDivElement;
	private cancelButton?: HTMLDivElement;
	private renameButton?: HTMLDivElement;

	private customerId: string = "";
	private boardId: string = "";

	private onNameChangedHandler?: (name: string) => void;

	public init() {
		this.backgroundBlockerElem = document.querySelector("#rename-board-background-blocker") as HTMLDivElement;
		this.containerElem = document.querySelector("#rename-board-dialog-container") as HTMLDivElement;
		this.inputElem = document.querySelector("#rename-board-name") as HTMLInputElement;
		this.errorElem = document.querySelector("#rename-board-error") as HTMLDivElement;
		this.cancelButton = document.querySelector("#rename-board-button-cancel") as HTMLDivElement;
		this.renameButton = document.querySelector("#rename-board-button-rename") as HTMLDivElement;

		this.hookEvents();
	}

	public onShow(customerId: string, boardId: string, name: string, onNameChanged: (name: string) => void) {
		this.customerId = customerId;
		this.boardId = boardId;
		this.onNameChangedHandler = onNameChanged;

		if (this.renameButton) {
			this.renameButton.innerText = "Rename";
		}

		if (this.inputElem) {
			this.inputElem.value = name;
		}

		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.remove("owHidden");
		}

		if (this.containerElem) {
			this.containerElem.classList.remove("owHidden");
		}

		if (this.errorElem) {
			this.errorElem.classList.add("owHidden");
		}
	}

	public onHide() {
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.add("owHidden");
		}

		if (this.containerElem) {
			this.containerElem.classList.add("owHidden");
		}
	}

	private getError(name: string) {
		if (name.length === 0) {
			return "Please enter a name for your board.";
		}

		if (!/([a-zA-Z0-9 _-]+)/g.test(name)) {
			return "Room names can only contain letters, numbers, spaces, slashes, and underscores.";
		}

		return "";
	}

	private async onRename(event: MouseEvent) {
		event.preventDefault();
		let boardName = "";
		if (this.inputElem) {
			boardName = this.inputElem.value;
		}

		const errorMessage = this.getError(boardName);

		if (this.errorElem) {
			if (errorMessage) {
				this.errorElem.classList.remove("owHidden");
				this.errorElem.innerText = errorMessage;
			} else {
				this.errorElem.classList.add("owHidden");
			}
		}

		if (this.renameButton) {
			this.renameButton.innerText = "Renaming...";
		}

		if (!errorMessage) {
			try {
				const board: IBoard = await postAjaxJSON("/moodboard/rename", {
					customerId: this.customerId,
					boardId: this.boardId,
					boardName
				});

				trackEvent("Button", "Rename Board", `BoardId: ${this.boardId}`);

				if (this.onNameChangedHandler) {
					this.onNameChangedHandler(board.name);
					this.onHide();
				}
			} catch (err) {
				if (this.errorElem) {
					this.errorElem.innerHTML = err.message;
					this.errorElem.classList.remove("owHidden");
				}
			}
		}
	}

	private hookEvents() {
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.addEventListener(Listeners.CLICK_EVENT, this.onHide.bind(this));
		}

		if (this.cancelButton) {
			this.cancelButton.addEventListener(Listeners.CLICK_EVENT, this.onHide.bind(this));
		}

		if (this.renameButton) {
			this.renameButton.addEventListener(Listeners.CLICK_EVENT, this.onRename.bind(this));
		}
	}
}
