import { CommonValue, DEFAULT_BRAND, LocalStorageKeys } from "../enumerations/utils";
import { DataElements, Listeners } from "../enumerations/web";
import { IBoard } from "../interfaces/boardInterfaces";
import { postAjaxJSON } from "../utils/ajax";
import { getLocalStorage, setLocalStorage } from "../utils/storage";
import { trackEvent } from "../utils/tracking";
import { GuestAlertDialog } from "./guestAlertDialog";

export class CreateBoardDialog {
	private backgroundBlockerElem?: HTMLDivElement;
	private roomTypeContainerElem?: HTMLDivElement;
	private roomTypeCloseButton?: HTMLDivElement;
	private roomTypeNextButton?: HTMLButtonElement;
	private templateContainerElem?: HTMLDivElement;
	private templateCloseButton?: HTMLDivElement;
	private templateBackButton?: HTMLDivElement;
	private templateCreateButton?: HTMLButtonElement;
	private templateListElems: HTMLDivElement[] = [];
	private templateItemElems: HTMLDivElement[] = [];

	private isGuest: boolean = false;
	private guestBoardId: string = "";

	private customerId: string = "";
	private roomTypeId: number = 0;
	private roomTypeLabel: string = "";
	private brand: string = "";
	private templateId: number = 0;
	private isPending = false;

	public init() {
		this.customerId = (document.querySelector(DataElements.CUSTOMER_ID_SELECTOR) as HTMLInputElement).value;
		this.backgroundBlockerElem = document.querySelector("#create-board-background-blocker") as HTMLDivElement;
		this.roomTypeContainerElem = document.querySelector("#create-board-room-type-container") as HTMLDivElement;
		this.roomTypeCloseButton = document.querySelector("#create-board-room-type-close-button") as HTMLDivElement;
		this.roomTypeNextButton = document.querySelector("#create-board-room-type-button-next") as HTMLButtonElement;
		this.templateContainerElem = document.querySelector("#create-board-template-container") as HTMLDivElement;
		this.templateCloseButton = document.querySelector("#create-board-template-close-button") as HTMLDivElement;
		this.templateBackButton = document.querySelector("#create-board-template-back-button") as HTMLDivElement;
		this.templateCreateButton = document.querySelector("#create-board-template-button-create") as HTMLButtonElement;
		this.templateListElems = Array.from(
			document.querySelectorAll("#create-board-template-container .create-board-dialog-list")
		) as HTMLDivElement[];
		this.templateItemElems = Array.from(
			document.querySelectorAll(".create-board-template-item")
		) as HTMLDivElement[];

		this.isGuest = Boolean((document.querySelector(DataElements.IS_GUEST_SELECTOR) as HTMLInputElement).value);
		this.guestBoardId = getLocalStorage(LocalStorageKeys.GUEST_BOARD_ID, "");

		if (this.templateCreateButton) {
			this.templateCreateButton.innerText = "Get Started";
		}

		this.hookEvents();
	}

	public onShow(event: MouseEvent) {
		if (this.isGuest && this.guestBoardId) {
			// User is guest and already has a plan, alert them.
			GUEST_ALERT_DIALOG.onShow();
			return;
		}
		this.onShowRoomTypes(event);
	}

	public onSelectRoomType(event: MouseEvent) {
		const inputElem = (event.currentTarget as any) as HTMLInputElement;
		if (!inputElem) {
			return;
		}

		const match = inputElem.id.match(/[\d]+/i);

		if (match) {
			this.roomTypeId = parseInt(match[0], CommonValue.PARSE_RADIX);
			this.roomTypeLabel = inputElem.value;
			this.brand = inputElem.getAttribute("data-brand") || DEFAULT_BRAND;

			trackEvent("Button", "Select Room Type", this.roomTypeLabel);

			if (this.roomTypeNextButton && this.roomTypeId > 0) {
				this.roomTypeNextButton.disabled = false;
			}

			if (this.templateCreateButton) {
				this.templateCreateButton.disabled = true;
			}

			this.refreshTempalteList();

			this.templateItemElems.forEach((templateItemElem: HTMLDivElement) => {
				templateItemElem.classList.remove("selected");
			});
		}
	}

	public onSelectTemplate(event: MouseEvent) {
		const inputElem = (event.currentTarget as any) as HTMLInputElement;
		if (!inputElem) {
			return;
		}

		this.templateItemElems.forEach((templateItemElem: HTMLDivElement) => {
			templateItemElem.classList.remove("selected");
		});
		inputElem.classList.add("selected");

		const match = inputElem.id.match(/[\d]+/i);

		if (match) {
			this.templateId = parseInt(match[0], CommonValue.PARSE_RADIX);

			trackEvent("Button", "Select Room Template", `BoardId: ${String(this.templateId)}`);

			if (this.templateCreateButton) {
				this.templateCreateButton.disabled = false;
			}

			this.refreshTempalteList();
		}
	}

	private hookEvents() {
		if (this.roomTypeCloseButton) {
			this.roomTypeCloseButton.addEventListener(Listeners.CLICK_EVENT, this.onCloseRoomTypes.bind(this));
		}
		if (this.roomTypeNextButton) {
			this.roomTypeNextButton.addEventListener(Listeners.CLICK_EVENT, this.onShowRoomTemplates.bind(this));
		}
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.addEventListener(Listeners.CLICK_EVENT, this.onCloseRoomTypes.bind(this));
			this.backgroundBlockerElem.addEventListener(Listeners.CLICK_EVENT, this.onCloseRoomTemplates.bind(this));
		}
		if (this.templateCloseButton) {
			this.templateCloseButton.addEventListener(Listeners.CLICK_EVENT, this.onCloseRoomTemplates.bind(this));
		}
		if (this.templateBackButton) {
			this.templateBackButton.addEventListener(Listeners.CLICK_EVENT, this.onShowRoomTypes.bind(this));
		}
		if (this.templateCreateButton) {
			this.templateCreateButton.addEventListener(Listeners.CLICK_EVENT, this.onCreateBoard.bind(this));
		}
	}

	private refreshTempalteList() {
		this.templateListElems.forEach((templateListElem: HTMLDivElement) => {
			if (templateListElem.id === `create-board-dialog-list-${this.roomTypeId}`) {
				templateListElem.classList.remove("owHidden");
			} else {
				templateListElem.classList.add("owHidden");
			}
		});
	}

	private onShowRoomTypes(event: MouseEvent) {
		this.onCloseRoomTemplates(event);
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.remove("owHidden");
		}
		if (this.roomTypeContainerElem) {
			this.roomTypeContainerElem.classList.remove("owHidden");
			this.roomTypeContainerElem.classList.remove("owFadeOut");
			this.roomTypeContainerElem.classList.add("owFadeIn");
			this.roomTypeContainerElem.classList.remove("owNoPointer");
		}
	}

	private onCloseRoomTypes(event: MouseEvent) {
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.add("owHidden");
		}
		if (this.roomTypeContainerElem) {
			this.roomTypeContainerElem.classList.add("owFadeOut");
			this.roomTypeContainerElem.classList.remove("owFadeIn");
			this.roomTypeContainerElem.classList.add("owNoPointer");
		}
	}

	private onShowRoomTemplates(event: MouseEvent) {
		if (!this.roomTypeNextButton || this.roomTypeNextButton.disabled) {
			return;
		}

		this.onCloseRoomTypes(event);
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.remove("owHidden");
		}
		// Hide room type dialog
		if (this.roomTypeContainerElem) {
			this.roomTypeContainerElem.classList.add("owFadeOut");
			this.roomTypeContainerElem.classList.remove("owFadeIn");
			this.roomTypeContainerElem.classList.add("owNoPointer");
		}

		// Show template dialog
		if (this.templateContainerElem) {
			this.templateContainerElem.classList.remove("owHidden");
			this.templateContainerElem.classList.remove("owFadeOut");
			this.templateContainerElem.classList.add("owFadeIn");
			this.templateContainerElem.classList.remove("owNoPointer");
		}
	}

	private onCloseRoomTemplates(event: MouseEvent) {
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.add("owHidden");
		}
		if (this.templateContainerElem) {
			this.templateContainerElem.classList.remove("owFadeIn");
			this.templateContainerElem.classList.add("owFadeOut");
			this.templateContainerElem.classList.add("owNoPointer");
		}
	}

	private async onCreateBoard(event: MouseEvent) {
		if (!this.templateCreateButton || this.templateCreateButton.disabled || this.isPending) {
			return;
		}

		this.templateCreateButton.disabled = true;
		this.templateCreateButton.innerText = "Getting Started...";

		try {
			this.isPending = true;
			trackEvent("Button", "Create Board", `${this.roomTypeLabel} TemplateId: ${this.templateId}`);
			const board: IBoard = await postAjaxJSON("/moodboard/create", {
				templateId: this.templateId,
				customerId: this.customerId,
				boardName: this.roomTypeLabel,
				boardType: this.roomTypeLabel,
				brand: this.brand
			});

			if (this.isGuest) {
				setLocalStorage(LocalStorageKeys.GUEST_BOARD_ID, String(board.id));
			}

			window.location.href = `/moodboard/${board.id}`;
		} catch (err) {
			// tslint:disable-next-line:no-console
			console.error(err.stack);
			this.templateCreateButton.disabled = false;
			this.templateCreateButton.innerText = "Get Started";
		} finally {
			this.isPending = false;
		}
	}
}

declare const GUEST_ALERT_DIALOG: GuestAlertDialog;
