import { Listeners } from "../enumerations/web";

export class Warning {
	public static readonly WARNING_REJECT_BTN_SELECTOR = ".warning .response .reject";
	public static readonly WARNING_CONFIRM_BTN_SELECTOR = ".warning .response .confirm";

	public warning?: HTMLElement;
	public rejectBtn?: HTMLElement;
	public confirmBtn?: HTMLElement;

	constructor(warning: HTMLElement) {
		try {
			const self = Warning;
			this.warning = warning;
			this.rejectBtn = this.warning.querySelector(self.WARNING_REJECT_BTN_SELECTOR) as HTMLElement;
			this.confirmBtn = this.warning.querySelector(self.WARNING_CONFIRM_BTN_SELECTOR) as HTMLElement;
		} catch (err) {
			// tslint:disable-next-line:no-console
			console.log(err);
		}
	}

	public show() {
		if (this.warning) {
			this.warning.classList.add("is-active");
		}
	}

	public hide() {
		if (this.warning && this.warning.classList.contains("is-active")) {
			this.warning.classList.remove("is-active");
		}
	}

	public addConfirmEvent(confirmCallback: (event: Event) => void) {
		if (this.confirmBtn) {
			this.confirmBtn.addEventListener(Listeners.CLICK_EVENT, confirmCallback);
		}
	}

	public addRejectEvent(rejectCallback: (event: Event) => void) {
		if (this.rejectBtn) {
			this.rejectBtn.addEventListener(Listeners.CLICK_EVENT, rejectCallback);
		}
	}
}
