import { LocalStorageKeys } from "../enumerations/utils";
import { Listeners } from "../enumerations/web";
import { IBoard } from "../interfaces/boardInterfaces";
import { postAjaxJSON } from "../utils/ajax";
import { getLocalStorage, setLocalStorage } from "../utils/storage";
import { trackEvent } from "../utils/tracking";

export class DeleteBoardDialog {
	private backgroundBlockerElem?: HTMLDivElement;
	private containerElem?: HTMLDivElement;
	private cancelButton?: HTMLDivElement;
	private deleteButton?: HTMLDivElement;

	private boardId: string = "";

	public init() {
		this.backgroundBlockerElem = document.querySelector("#delete-board-background-blocker") as HTMLDivElement;
		this.containerElem = document.querySelector("#delete-board-dialog-container") as HTMLDivElement;
		this.cancelButton = document.querySelector("#delete-board-button-cancel") as HTMLDivElement;
		this.deleteButton = document.querySelector("#delete-board-button-delete") as HTMLDivElement;

		this.hookEvents();
	}

	public onShow(boardId: string) {
		this.boardId = boardId;

		if (this.deleteButton) {
			this.deleteButton.innerText = "Delete";
		}

		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.remove("owHidden");
		}

		if (this.containerElem) {
			this.containerElem.classList.remove("owHidden");
		}
	}

	public onHide() {
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.add("owHidden");
		}

		if (this.containerElem) {
			this.containerElem.classList.add("owHidden");
		}
	}

	private async onDelete(event: MouseEvent) {
		event.preventDefault();

		if (this.deleteButton) {
			this.deleteButton.innerText = "Deleting...";
		}

		try {
			const board: IBoard = await postAjaxJSON("/moodboard/delete", {
				boardId: this.boardId
			});

			const guestBoardId = getLocalStorage(LocalStorageKeys.GUEST_BOARD_ID, "");
			if (guestBoardId && guestBoardId === this.boardId) {
				setLocalStorage(LocalStorageKeys.GUEST_BOARD_ID, "");
			}

			trackEvent("Button", "Delete Board", `BoardId: ${String(board.id)}`);
		} catch (err) {
			// tslint:disable-next-line:no-console
			console.error(err.stack);
		}

		location.href = "/portfolio";
	}

	private hookEvents() {
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.addEventListener(Listeners.CLICK_EVENT, this.onHide.bind(this));
		}

		if (this.cancelButton) {
			this.cancelButton.addEventListener(Listeners.CLICK_EVENT, this.onHide.bind(this));
		}

		if (this.deleteButton) {
			this.deleteButton.addEventListener(Listeners.CLICK_EVENT, this.onDelete.bind(this));
		}
	}
}
