import { EventEmitter } from "events";

import { trackEvent } from "../utils/tracking";
import { Warning } from "./warning";

export class SignOutWarning extends EventEmitter {
	public static readonly SIGN_OUT_WARNING_SELECTOR = "nav .sign-out-warning";
	public static readonly SIGN_OUT_WARNING_REJECT_BTN_SELECTOR = ".modal .response .reject";
	public static readonly SIGN_OUT_WARNING_CONFIRM_BTN_SELECTOR = ".modal .response .confirm";
	// this default destination could be wrong:
	public static readonly DEFAULT_DESTINATION = "/moodboard";
	public static readonly LOG_OUT_DESTINATION = "/auth/logout";

	private warning: Warning;
	private signOutWarning: HTMLElement;
	private discardDestination: string;
	private logOutDestination: string;

	constructor() {
		super();

		const self = SignOutWarning;
		this.signOutWarning = document.querySelector(self.SIGN_OUT_WARNING_SELECTOR) as HTMLElement;
		this.warning = new Warning(this.signOutWarning);

		this.discardDestination = self.DEFAULT_DESTINATION;
		this.logOutDestination = self.LOG_OUT_DESTINATION;
		this.logOut = this.logOut.bind(this);

		this.warning.addRejectEvent((event) => {
			event.preventDefault();
			this.hide();
		});

		this.warning.addConfirmEvent((event) => {
			event.preventDefault();
			this.logOut();
		});
	}

	public get discardLocation() {
		return this.discardDestination;
	}

	public reassignLocation(destination?: string) {
		location.assign(destination || this.discardDestination);
	}

	public show() {
		this.warning.show();
	}

	public hide() {
		this.warning.hide();
	}

	public logOut() {
		trackEvent("Button", "Logout");
		this.reassignLocation(this.logOutDestination);
		this.redirect(this.logOutDestination);
	}

	private redirect(destination: string) {
		this.discardDestination = destination;
		this.emit("redirect");
	}
}
