import { LocalStorageKeys } from "../enumerations/utils";
import { DataElements, Listeners } from "../enumerations/web";
import { postAjaxJSON, wait } from "../utils/ajax";
import { setLocalStorage } from "../utils/storage";
import { trackEvent } from "../utils/tracking";

export class GuestImportDialog {
	private backgroundBlockerElem?: HTMLDivElement;
	private containerElem?: HTMLDivElement;
	private cancelButton?: HTMLDivElement;
	private importButton?: HTMLDivElement;

	private boardId: string = "";

	public init() {
		this.backgroundBlockerElem = document.querySelector("#guest-import-background-blocker") as HTMLDivElement;
		this.containerElem = document.querySelector("#guest-import-dialog-container") as HTMLDivElement;
		this.cancelButton = document.querySelector("#guest-import-button-cancel") as HTMLDivElement;
		this.importButton = document.querySelector("#guest-import-button-import") as HTMLDivElement;

		this.hookEvents();
	}

	public onShow(boardId: string) {
		if (this.cancelButton) {
			this.cancelButton.innerText = "No";
		}

		if (this.importButton) {
			this.importButton.innerText = "Yes, Import";
		}

		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.remove("owHidden");
		}

		if (this.containerElem) {
			this.containerElem.classList.remove("owHidden");
		}

		this.boardId = boardId;
	}

	public onHide() {
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.add("owHidden");
		}

		if (this.containerElem) {
			this.containerElem.classList.add("owHidden");
		}
	}

	private async onCancel(event: MouseEvent) {
		event.preventDefault();

		if (this.cancelButton) {
			this.cancelButton.innerText = "Processing...";
		}

		// Cancel import and forget about the guest board
		try {
			setLocalStorage(LocalStorageKeys.GUEST_BOARD_ID, "");
			await wait(1000);
		} catch (err) {
			// tslint:disable-next-line:no-console
			console.error(err.stack);
		}

		this.onHide();
	}

	private async onImport(event: MouseEvent) {
		event.preventDefault();

		trackEvent("Button", "Guest Board Import");

		const customerId = (document.querySelector(DataElements.CUSTOMER_ID_SELECTOR) as HTMLInputElement).value;

		if (this.importButton) {
			this.importButton.innerText = "Importing...";
		}

		// Import the guest board
		try {
			await postAjaxJSON("/moodboard/create", {
				templateId: this.boardId,
				customerId
			});
			setLocalStorage(LocalStorageKeys.GUEST_BOARD_ID, "");
			await wait(1000);
		} catch (err) {
			// tslint:disable-next-line:no-console
			console.error(err.stack);
		}

		window.location.href = "/portfolio";

		this.onHide();
	}

	private hookEvents() {
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.addEventListener(Listeners.CLICK_EVENT, this.onImport.bind(this));
		}

		if (this.cancelButton) {
			this.cancelButton.addEventListener(Listeners.CLICK_EVENT, this.onCancel.bind(this));
		}

		if (this.importButton) {
			this.importButton.addEventListener(Listeners.CLICK_EVENT, this.onImport.bind(this));
		}
	}
}
