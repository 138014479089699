import { Listeners } from "../enumerations/web";
import { trackEvent } from "../utils/tracking";

export class GuestAlertDialog {
	private backgroundBlockerElem?: HTMLDivElement;
	private containerElem?: HTMLDivElement;
	private cancelButton?: HTMLDivElement;
	private signinButton?: HTMLDivElement;

	public init() {
		this.backgroundBlockerElem = document.querySelector("#guest-alert-background-blocker") as HTMLDivElement;
		this.containerElem = document.querySelector("#guest-alert-dialog-container") as HTMLDivElement;
		this.cancelButton = document.querySelector("#guest-alert-button-cancel") as HTMLDivElement;
		this.signinButton = document.querySelector("#guest-alert-button-sign-in") as HTMLDivElement;

		this.hookEvents();
	}

	public onShow() {
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.remove("owHidden");
		}

		if (this.containerElem) {
			this.containerElem.classList.remove("owHidden");
		}
	}

	public onHide() {
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.classList.add("owHidden");
		}

		if (this.containerElem) {
			this.containerElem.classList.add("owHidden");
		}
	}

	private async onSignIn(event: MouseEvent) {
		event.preventDefault();

		trackEvent("Button", "Guest Sign In");

		window.location.href = "/splash";
	}

	private hookEvents() {
		if (this.backgroundBlockerElem) {
			this.backgroundBlockerElem.addEventListener(Listeners.CLICK_EVENT, this.onHide.bind(this));
		}

		if (this.cancelButton) {
			this.cancelButton.addEventListener(Listeners.CLICK_EVENT, this.onHide.bind(this));
		}

		if (this.signinButton) {
			this.signinButton.addEventListener(Listeners.CLICK_EVENT, this.onSignIn.bind(this));
		}
	}
}
